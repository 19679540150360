<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-13 10:43:03
-->
<template>
  <div>
  <el-dialog
    v-if="dialogVisible"
    :title="propTitle"
    :visible.sync="dialogVisible"
    width="400px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      :model="personalObj"
      :rules="dataRule"
      ref="personalObj"
      label-width="80px"
      @keyup.enter.native="submitHandle()"
    >
      <el-form-item label="姓名" prop="userName">
        <div class="select-box-info">
          <el-tag effect="plain" v-if="personalObj.userName">{{personalObj.userName}}</el-tag>
          <el-tag effect="dark" @click="personnelShow = true"><i class="el-icon-plus"></i>人员</el-tag>
      </div>
      </el-form-item>
      <el-form-item label="负责部门" prop="deptIds">
        <GlobalTreeSelect
          style="width: 280px"
          multiple
          v-model="personalObj.deptIds"
          filterable
          :checkStrictly="true"
          :treeProps="visibleDeptProps"
          :data="visibleDeptData"
        >
        </GlobalTreeSelect>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="_submit()">确 定</el-button>
    </span>
  </el-dialog>
  <!-- 选择人员 -->
  <SelectPersonnel
    v-if="personnelShow"
    :personnelShow="personnelShow"
    readio="readio"
    @submitHandle="_submitHandle"
    type="lecturerSetting"
    title="选择人员"
  />
  </div>
</template>

<script>
import { getManageFrameDeptApi } from '@/api/api'
import { getResponseUserAddOrEditApi } from '@/api/settings'
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import SelectPersonnel from '@/components/selectPersonnel/index'
export default {
  components: {
    GlobalTreeSelect,
    SelectPersonnel
  },
  props: {
    propTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialogVisible: false,
      visibleDeptValue: '', // 可见范围--部门
      visibleDeptData: [], // 可见范围--部门数据
      visibleDeptProps: {
        label: 'name',
        children: 'childList'
      },
      dataForm: {
        parentId: 0,
        group: '',
        groupNameList: [
          {
            groupName: ''
          }
        ], // 分组名称
        visibleRange: 1, // 可见范围
        visibleDeptIds: null
      },
      dataRule: {
        userName: [
          { required: true, message: '请选择姓名', trigger: ['blur'] }
        ],
        deptIds: [
          { required: true, message: '请选择', trigger: ['blur'] }
        ]
      },
      personnelShow: false,
      personalObj: {
        deptIds: '',
        createDeptId: '',
        userId: '',
        userName: ''
      }
    }
  },
  created () {
    this.getDeptList()
  },
  methods: {
    show (row) {
      if (row) {
        this.personalObj = row
        console.log(this.personalObj)
      } else {
        this.personalObj = {
          deptIds: '',
          createDeptId: '',
          userId: '',
          userName: ''
        }
      }
      this.dialogVisible = true
    },
    handleClose () {
      this.personalObj = {}
      this.dialogVisible = false
    },
    getDeptList () {
      getManageFrameDeptApi().then(res => {
        if (res.code === 200) {
          this.visibleDeptData = JSON.parse(JSON.stringify(res.data))
        }
      })
    },

    _submitHandle (arr) {
      this.personalObj = {
        ...this.personalObj,
        createDeptId: arr[0].parentId,
        userId: arr[0].userId,
        userName: arr[0].name,
        createDeptName: arr[0].parentName
      }
      this.personnelShow = false
    },
    _submit () {
      this.$refs.personalObj.validate((valid) => {
        if (valid) {
          getResponseUserAddOrEditApi(this.personalObj).then(res => {
            if (res.code === 200) {
              this.$parent._query()
              this.dialogVisible = false
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.select-box-info{
  .el-tag{
    width: 78px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    margin-right: 12px;
  }
}
</style>
